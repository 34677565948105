import { fetcherAmplify } from "@/libs/fetcher";
import * as mutations from "@/graphql/mutations";
import * as queries from "@/graphql/queries";
import * as subscriptions from "@/graphql/subscriptions";
import {
  transferCustomTask as transferCustomTaskMutation,
  updatePhoneNumber as updatePhoneNumberMutation,
  deleteTrafficGroup as deleteTrafficGroupMutation,
  createTrafficGroup as createTrafficGroupMutation,
  removeUserFromGroup as removeUserFromGroupMutation,
  addUser2TrafficGroup as addUser2TrafficGroupMutation,
} from "@/graphql/mutations";

import {
  describePhoneNumber as describePhoneNumberQuery,
  describeTrafficDistributionGroup as describeTrafficDistributionGroupQuery,
  replicateInstance as replicateInstanceQuery,
  replicateCrossRegionResources as replicateCrossRegionResourcesQuery,
  getTrafficDistribution as getTrafficDistributionQuery,
  listTrafficDistributionGroups as listTrafficDistributionGroupsQuery,
  listTrafficDistributionGroupUsers as listTrafficDistributionGroupUsersQuery,
  listPhoneNumbers as listPhoneNumbersQuery,
} from "@/graphql/queries";
export const updateSettings = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateSettings,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const transferCustomTask = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: transferCustomTaskMutation,
    variables: payload,
  });

  return result;
};

//Activity Module Calls
export const getLevelListsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getLevelLists,
    variables: payload,
  });

  return result;
};

export const describeActivityQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.describeActivity,
    variables: { ...payload },
  });

  return result;
};

export const listActivityQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listActivity,
    variables: payload,
  });

  return result;
};

export const listTranscriptsHistoryQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listTranscriptsHistory,
    variables: payload,
  });
  return result;
};

export const updateActivityMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateActivity,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listCtrQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listCtr,
    variables: { ...payload },
  });

  return result;
};

export const listRelatedActivityQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listRelatedActivity,
    variables: { ...payload },
  });

  return result;
};

//Announcement Module Calls

export const listAnnouncementsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listAnnouncements,
    variables: { ...payload },
  });

  return result;
};

//Authentication Module Calls

export const heartbeatMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.heartbeat,
    variables: {
      input: payload,
    },
  });

  return result;
};

//Health Module Calls

export const getHealthQuery = async () => {
  const result = await fetcherAmplify().graphql({
    query: queries.getHealth,
  });

  return result;
};

//Holiday Module Calls
export const listScheduleEventsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listScheduleEvents,
    variables: payload,
  });

  return result;
};

export const createScheduleEventMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createScheduleEvent,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateScheduleEventMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateScheduleEvent,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const deleteScheduleEventMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteScheduleEvent,
    variables: {
      input: payload,
    },
  });

  return result;
};

//Iframe Integtation Module Calls

export const createIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createThirdPartyIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const deleteThirdPartyIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteThirdPartyIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listThirdPartyIntegrationQuery = async () => {
  const result = await fetcherAmplify().graphql({
    query: queries.listThirdPartyIntegration,
  });

  return result;
};

export const updateThirdPartyIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateThirdPartyIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

//Integrations Module

export const createSmsTroughPinpointIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createSmsTroughPinpointIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listIntegrationsQuery = async () => {
  const result = await fetcherAmplify().graphql({
    query: queries.getListOfIntegrations,
  });

  return result;
};

export const getIntegrationsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getIntegration,
    variables: payload,
  });

  return result;
};

export const checkIntegrationCloudFormationStatusQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.checkIntegrationCloudFormationStatus,
    variables: payload,
  });

  return result;
};

export const updateSmsTroughPinpointIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateSmsTroughPinpointIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const deleteIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listContactFlowsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listContactFlows,
    variables: payload,
  });

  return result;
};

export const createOffice365IntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createOffice365Integration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateOffice365IntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateOffice365Integration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const createOfficeDynamicsIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createOfficeDynamicsIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateOfficeDynamicsIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateOfficeDynamicsIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const checkEmailIdentitiesVerificationStatusQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.checkEmailIdentitiesVerificationStatus,
    variables: payload,
  });

  return result;
};

export const listCustomStacksIntegrationsQuery = async () => {
  const result = await fetcherAmplify().graphql({
    query: queries.listCustomStacksIntegrations,
  });

  return result;
};

export const getCustomStackIntegrationQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getCustomStackIntegration,
    variables: payload,
  });

  return result;
};

export const deployCustomStackIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deployCustomStackIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const deleteCustomStackIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteCustomStackIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const createGoogleAuthIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createGoogleAuthIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateGoogleAuthIntegrationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateGoogleAuthIntegration,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const createQuickSightQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.createQuickSight,
    variables: payload,
  });

  return result;
};

//Livelook Module

export const onUpdateLivelookContactsSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onUpdateLivelookContacts,
    variables: payload,
  });

  return result;
};

export const listLivelookContactsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listLivelookContacts,
    variables: payload,
  });

  return result;
};

//New Reports Module

export const getListDashboardsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getListDashboards,
    variables: payload,
  });

  return result;
};

export const getQuickSightEmbedURLQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getQuickSightEmbedURL,
    variables: payload,
  });

  return result;
};

//Self Service Module

export const getLatencyQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.latency,
    variables: payload,
  });

  return result;
};

//Support Module

export const createSupportCaseMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createSupportCase,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listSupportCasesQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listSupportCases,
    variables: {
      account: payload,
    },
  });

  return result;
};

//Survey Module

export const listSurveysQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listSurveys,
    variables: payload,
  });

  return result;
};

export const createSurveyMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createSurvey,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateSurveyMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateSurvey,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const deleteSurveyMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteSurvey,
    variables: {
      input: payload,
    },
  });

  return result;
};

//Translation Module

export const createTranslationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createTranslation,
    variables: {
      input: payload,
    },
  });

  return result;
};

//Video Call Module

export const getAttendeeVideoCallChimeQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getAttendeeVideoCallChime,
    variables: payload,
  });

  return result;
};

export const getMeetingVideoCallChimeQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getMeetingVideoCallChime,
    variables: payload,
  });

  return result;
};

export const createMeetingVideoCallChimeMutation = async () => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createMeetingVideoCallChime,
  });

  return result;
};

export const createAttendeeVideoCallChimeMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createAttendeeVideoCallChime,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const deleteAttendeeVideoCallChimeMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteAttendeeVideoCallChime,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const deleteMeetingVideoCallChimeMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteMeetingVideoCallChime,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const startMeetingTranscriptionVideoCallChimeMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.startMeetingTranscriptionVideoCallChime,
    variables: {
      input: payload,
    },
  });

  return result;
};

//Users Module

export const syncUsersMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.syncUsers,
    variables: payload,
  });

  return result;
};

export const promoteUserMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.promoteUser,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listUsersCompactQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listUsersCompact,
    variables: payload,
  });

  return result;
};

export const deleteUserMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteUser,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const createConnectUserMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createConnectUser,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateUserMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateUser,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateUserSecurityProfileMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateUserSecurityProfile,
    variables: {
      input: payload,
    },
  });

  return result;
};

// Contact Module

export const enableMonitorContactMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.enableMonitorContact,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const getInstanceAttributeQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getInstanceAttribute,
    variables: payload,
  });

  return result;
};

//Contacts Module

export const onGlobalContactSubscription = async (payload) => {
  const result = await fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onGlobalContact,
    variables: payload,
  });

  return result;
};

export const onDeleteGlobalContactSubscription = async (payload) => {
  const result = await fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onDeleteGlobalContact,
    variables: payload,
  });

  return result;
};

export const listContactsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listContacts,
    variables: payload,
  });

  return result;
};

export const createContactMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createContact,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateContactMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateContact,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const deleteContactMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteContact,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateFavoriteItemsMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateFavoriteItems,
    variables: {
      input: payload,
    },
  });

  return result;
};

//Queue Module

export const getQueueMetricsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getQueueMetrics,
    variables: payload,
  });

  return result;
};

export const listSkillQueuesQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listSkillQueues,
    variables: payload,
  });

  return result;
};

export const getSkillsListQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getSkillsList,
    variables: payload,
  });

  return result;
};

export const queueRebalancingTransferUserMoveMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.queueRebalancingTransferUserMove,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const queueRebalancingTransferUserCopyMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.queueRebalancingTransferUserCopy,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listSkillUsersQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listSkillUsers,
    variables: payload,
  });

  return result;
};

export const createSkillMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createSkill,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const assignSkillMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.assignSkill,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const removeSkillMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.removeSkill,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listConnectBackupVersionsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listConnectBackupVersions,
    variables: payload,
  });

  return result;
};

export const createConnectBackupVersionMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createConnectBackupVersion,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const deleteConnectBackupVersionMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteConnectBackupVersion,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const applyConnectBackupVersionMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.applyConnectBackupVersion,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const queueRebalancingListLogQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.queueRebalancingListLog,
    variables: payload,
  });

  return result;
};

//Report Module

export const createReportMutation = async (payload, token) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createReport,
    variables: {
      input: payload,
      token: token,
    },
  });

  return result;
};

export const updateReportMutation = async (payload, token) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateReport,
    variables: {
      input: payload,
      token: token,
    },
  });

  return result;
};

export const listReportsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listReports,
    variables: payload,
  });

  return result;
};

export const deleteReportMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteReport,
    variables: payload,
  });

  return result;
};

export const getReportQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getReport,
    variables: payload,
  });

  return result;
};

export const updateReportingV2AccessMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateReportingV2Access,
    variables: {
      input: payload,
    },
  });

  return result;
};

//Channel Module

export const listConversationMessagesQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listConversationMessages,
    variables: payload,
  });

  return result;
};

export const closeConversationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.closeConversation,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const onNewConversationMessageSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onNewConversationMessage,
    variables: payload,
  });
  return result;
};

export const listWebhooksQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listWebhooks,
    variables: payload,
  });

  return result;
};

export const createWebhookChannelMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createWebhookChannel,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateWebhookChannelMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateWebhookChannel,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const deleteWebhookChannelMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteWebhookChannel,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const endEmailConversationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.endEmailConversation,
    variables: payload,
  });

  return result;
};

export const acceptEmailContactMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.acceptEmailContact,
    variables: payload,
  });

  return result;
};

export const deleteEmailServerConfigMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteEmailServerConfig,
    variables: payload,
  });

  return result;
};

export const deleteEmailFlowMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteEmailFlow,
    variables: payload,
  });

  return result;
};

export const listEmailServerConfigsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listEmailServerConfigs,
    variables: payload,
  });

  return result;
};

export const listEmailFlowsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listEmailFlows,
    variables: payload,
  });

  return result;
};

export const listEmailMessagesQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listEmailMessages,
    variables: payload,
  });

  return result;
};

export const createEmailServerConfigMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createEmailServerConfig,
    variables: payload,
  });

  return result;
};

export const updateEmailServerConfigMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateEmailServerConfig,
    variables: payload,
  });

  return result;
};

export const createEmailFlowMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createEmailFlow,
    variables: payload,
  });

  return result;
};

export const updateEmailFlowMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateEmailFlow,
    variables: payload,
  });

  return result;
};

export const getSignedS3URLQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getSignedS3URL,
    variables: payload,
  });

  return result;
};

export const outboundMessagesMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.outboundMessages,
    variables: payload,
  });

  return result;
};

export const listTaskTemplatesQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listTaskTemplates,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const sendConversationMessageMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.sendConversationMessage,
    variables: payload,
  });

  return result;
};

// Chat Module

export const getChatSuggestionsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getChatSuggestions,
    variables: payload,
  });

  return result;
};

export const newAgentChatParticipantEventSubscription = async (payload) => {
  const result = await fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.newAgentChatParticipantEvent,
    variables: payload,
  });

  return result;
};

export const getAgentChatConversationQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getAgentChatConversation,
    variables: payload,
  });

  return result;
};

export const newAgentChatMessageEventSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.newAgentChatMessageEvent,
    variables: payload,
  });

  return result;
};

export const newAgentChatMessageEventReceiverSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.newAgentChatMessageEventReceiver,
    variables: payload,
  });

  return result;
};

export const listAgentChatConversationsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listAgentChatConversations,
    variables: payload,
  });

  return result;
};

export const CreateAgentChatConversationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.CreateAgentChatConversation,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listAgentChatMessagesQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listAgentChatMessages,
    variables: payload,
  });

  return result;
};

export const getAvatarQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getAvatar,
    variables: payload,
  });

  return result;
};

export const UpdateAgentChatMessageMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.UpdateAgentChatMessage,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const MarkAllMessagesReadMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.MarkAllMessagesRead,
    variables: {
      input: payload,
    },
  });

  return result;
};

//Settings Module

export const onUpdateSettingsSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onUpdateSettings,
    variables: payload,
  });

  return result;
};

export const onNewDispositionSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onNewDisposition,
    variables: payload,
  });

  return result;
};

export const getSettingsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getSettings,
    variables: payload,
  });

  return result;
};

export const updateSettingsMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateSettings,
    variables: payload,
  });

  return result;
};

export const createDispositionMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createDisposition,
    variables: payload,
  });

  return result;
};

export const updateDispositionMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateDisposition,
    variables: payload,
  });

  return result;
};

export const createLevelListMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createLevelList,
    variables: payload,
  });

  return result;
};

export const createScriptMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createScript,
    variables: payload,
  });

  return result;
};

export const createPublicAPIBearerTokenMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createPublicAPIBearerToken,
    variables: payload,
  });

  return result;
};

export const deletePublicAPIBearerTokenMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deletePublicAPIBearerToken,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listPublicAPIBearerTokenQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listPublicAPIBearerToken,
    variables: payload,
  });

  return result;
};

export const updateLevelListMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateLevelList,
    variables: payload,
  });

  return result;
};

export const updateScriptMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateScript,
    variables: payload,
  });

  return result;
};

export const listDispositionsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listDispositions,
    variables: payload,
  });

  return result;
};

export const deleteDispositionMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteDisposition,
    variables: payload,
  });

  return result;
};

export const createCustomCRMMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createCustomCRM,
    variables: payload,
  });

  return result;
};

export const getCustomCRMQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getCustomCRM,
    variables: payload,
  });

  return result;
};

export const updateCustomCRMMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateCustomCRM,
    variables: payload,
  });

  return result;
};

export const deleteCustomCRMMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteCustomCRM,
    variables: payload,
  });

  return result;
};

export const createCustomUrlMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createCustomUrl,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateCustomUrlMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateCustomUrl,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const getCustomsLinksQuery = async () => {
  const result = await fetcherAmplify().graphql({
    query: queries.getCustomsLinks,
  });

  return result;
};

export const deleteCustomUrlMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteCustomUrl,
    variables: {
      input: payload,
    },
  });

  return result;
};

//Team Module

export const newInitiatorCandidateMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.newInitiatorCandidate,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const newPeerCandidateMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.newPeerCandidate,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listTeamQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listTeam,
    variables: payload,
  });

  return result;
};

export const createActivityMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createActivity,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listUsersIntegrationsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listUsersIntegrations,
    variables: payload,
  });

  return result;
};

export const onDeleteUserIntegrationsSubscription = async (payload) => {
  const result = await fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onDeleteUserIntegrations,
    variables: payload,
  });

  return result;
};

export const onUpdateUserIntegrationsSubscription = async (payload) => {
  const result = await fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onUpdateUserIntegrations,
    variables: payload,
  });

  return result;
};

export const onCreateUserIntegrationsSubscription = async (payload) => {
  const result = await fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onCreateUserIntegrations,
    variables: payload,
  });

  return result;
};

//Current User Module

export const getCurrentGmtEpochTimeStampQuery = async () => {
  const result = await fetcherAmplify().graphql({
    query: queries.getCurrentGmtEpochTimeStamp,
  });

  return result;
};

export const getNetworkTraversalQuery = async () => {
  const result = await fetcherAmplify().graphql({
    query: queries.getNetworkTraversal,
  });

  return result;
};

export const deleteSecurityProfileMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteSecurityProfile,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const getTasksQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getTasks,
    variables: payload,
  });

  return result;
};

export const listSecurityProfilesQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listSecurityProfiles,
    variables: payload,
  });

  return result;
};

export const listRoutingProfilesQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listRoutingProfiles,
    variables: payload,
  });

  return result;
};

export const createSecurityProfileMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createSecurityProfile,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateSecurityProfileMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateSecurityProfile,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const getAccountQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getAccount,
    variables: payload,
  });

  return result;
};

export const needsHelpMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.needsHelp,
    variables: payload,
  });

  return result;
};

export const onCreateUserSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onCreateUser,
    variables: payload,
  });

  return result;
};

export const onFinishedReportSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onFinishedReport,
    variables: payload,
  });

  return result;
};

export const onUpdateSecurityProfileSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onUpdateSecurityProfile,
    variables: payload,
  });

  return result;
};

export const onNeedsHelpSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onNeedsHelp,
    variables: payload,
  });

  return result;
};

export const onLoginSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onLogin,
    variables: payload,
  });

  return result;
};

export const onNewAnnouncementSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onNewAnnouncement,
    variables: payload,
  });

  return result;
};

export const updateAccountMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateAccount,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const onIntegrationChangedSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onIntegrationChanged,
    variables: payload,
  });

  return result;
};

export const onIntegrationDeletedSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onIntegrationDeleted,
    variables: payload,
  });

  return result;
};

export const onUpdateUsersSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onUpdateUsers,
    variables: payload,
  });

  return result;
};

export const onUpdateUserSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onUpdateUser,
    variables: payload,
  });

  return result;
};

export const onUpdateUserSecurityProfileSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onUpdateUserSecurityProfile,
    variables: payload,
  });

  return result;
};

export const onNewActivitySubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onNewActivity,
    variables: payload,
  });

  return result;
};

export const onNewActivityCustomerSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onNewActivityCustomer,
    variables: payload,
  });

  return result;
};

export const createAnnouncementMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createAnnouncement,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const getSecurityProfileQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getSecurityProfile,
    variables: payload,
  });

  return result;
};

export const deleteAnnouncementMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteAnnouncement,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const onDeletedAnnouncementSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onDeletedAnnouncement,
    variables: payload,
  });

  return result;
};

export const onDeleteUserSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onDeleteUser,
    variables: payload,
  });

  return result;
};

//Phone Module

export const createSmsFlowMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createSmsFlow,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const updateSmsFlowMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateSmsFlow,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const deleteSmsFlowMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteSmsFlow,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const listSmsFlowsQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.listSmsFlows,
    variables: payload,
  });

  return result;
};

export const AcceptSmsConversationMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.AcceptSmsConversation,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const onNewSmsMessageSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onNewSmsMessage,
    variables: payload,
  });

  return result;
};

export const onStatusChangeSmsContactSubscription = (payload) => {
  const result = fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.onStatusChangeSmsContact,
    variables: payload,
  });

  return result;
};

//Index Module

export const verifyCredsSubscription = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.verifyCreds,
    variables: payload,
  });

  return result;
};

export const updatePhoneNumber = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: updatePhoneNumberMutation,
    variables: payload,
  });

  return result;
};

export const describePhoneNumber = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: describePhoneNumberQuery,
    variables: payload,
  });

  return result;
};

export const describeTrafficDistributionGroup = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: describeTrafficDistributionGroupQuery,
    variables: payload,
  });

  return result;
};

export const replicateInstance = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: replicateInstanceQuery,
    variables: payload,
  });

  return result;
};

export const replicateCrossRegionResources = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: replicateCrossRegionResourcesQuery,
    variables: payload,
  });

  return result;
};

export const deleteTrafficGroup = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: deleteTrafficGroupMutation,
    variables: payload,
  });

  return result;
};

export const getTrafficDistribution = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: getTrafficDistributionQuery,
    variables: payload,
  });

  return result;
};

export const listTrafficDistributionGroups = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: listTrafficDistributionGroupsQuery,
    variables: payload,
  });

  return result;
};

export const listTrafficDistributionGroupUsers = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: listTrafficDistributionGroupUsersQuery,
    variables: payload,
  });

  return result;
};

export const listPhoneNumbers = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: listPhoneNumbersQuery,
    variables: payload,
  });

  return result;
};

export const createTrafficGroup = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: createTrafficGroupMutation,
    variables: payload,
  });

  return result;
};

export const removeUserFromGroup = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: removeUserFromGroupMutation,
    variables: payload,
  });

  return result;
};

export const addUser2TrafficGroup = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: addUser2TrafficGroupMutation,
    variables: payload,
  });

  return result;
};

//Agent Call

export const createAgentChimeMeetingEndpointMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createAgentChimeMeetingEndpoint,
    variables: payload,
  });
  return result;
};

export const updateAgentChimeCallEndpointMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.updateAgentChimeMeetingDetails,
    variables: payload,
  });
  return result;
};

export const deleteAgentChimeMeetingEndpointMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.deleteAgentChimeMeetingEndpoint,
    variables: payload,
  });
  return result;
};

export const getMeetingChimeInfoQuery = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: queries.getMeetingChimeInfo,
    variables: payload,
  });
  return result;
};

export const createAttendeeChimeCallEndpointMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.createAttendeeChimeCallEndpoint,
    variables: payload,
  });
  return result;
};

export const cleanupAgentCallStatusMutation = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: mutations.cleanupAgentCallStatus,
    variables: payload,
  });
  return result;
};

export const newAgentChimeCallEventSubscription = async (payload) => {
  const result = await fetcherAmplify().subscriptionsGraphql({
    query: subscriptions.newAgentChimeCallEvent,
    variables: payload,
  });
  return result;
};
