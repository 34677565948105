const supportedDevices = [
  { type: "headset", oem: "jabra", model: "Jabra Engage 50" },
  { type: "headset", oem: "jabra", model: "Jabra Engage 65" },
  { type: "headset", oem: "jabra", model: "Jabra Engage 75" },
  { type: "headset", oem: "jabra", model: "Jabra Biz" },
  { type: "headset", oem: "jabra", model: "Jabra Pro" },
];

const HANDLERSGEN2 = {
  headset: {
    jabra: () => import("./Handlers/JabraHandlerGen2"),
    poly: null,
    sennheiser: null,
    logitech: null,
  },
  webcam: {},
};

const HANDLERSGEN3 = {
  headset: {
    jabra: () => import("./Handlers/JabraHandlerGen3"),
    poly: null,
    sennheiser: null,
    logitech: null,
  },
  webcam: {},
};

function HIDHandler(type, oem, gen) {
  if (gen == 2) {
    return HANDLERSGEN2[type][oem];
  }
  if (gen == 3) {
    return HANDLERSGEN3[type][oem];
  }
}

export { supportedDevices, HIDHandler };
