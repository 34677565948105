import Vue from "vue";

let socket;
const queue = [];

import { PeerCallStatus } from "./constant";
import { Amplify } from "@aws-amplify/core";
import { DateTime } from "luxon";
import { Auth } from "aws-amplify";
import axios from "axios";
import { getApiRestEndpoint, getWebSocketEndPoint } from "@/utils/configs";
import { useActivityStore } from "@/piniaStore/activity";
import { useAuthenticationStore } from "@/piniaStore/authentication";
import { useChannelStore } from "@/piniaStore/channel";
import { useChatStore } from "@/piniaStore/chat";
import { useContactStore } from "@/piniaStore/contact";
import { useContactsStore } from "@/piniaStore/contacts";
import { useUiStore } from "@/piniaStore/ui";
import { usePermissionsStore } from "@/piniaStore/permissions";
import { useHidStore } from "@/piniaStore/hid";
import { useHolidayStore } from "@/piniaStore/holiday";
import { useIframeIntegrationsStore } from "@/piniaStore/iframeIntegrations";
import { useLogsStore } from "@/piniaStore/logs";
import { useMediaDevicesStore } from "@/piniaStore/mediaDevices";
import { useSettingsStore } from "@/piniaStore/settings";
import { usePhoneStore } from "@/piniaStore/phone";
import { useCurrentUserStore } from "@/piniaStore/current-user";
import { useApiStore } from "@/piniaStore/api";
import { useIndexStore } from "@/piniaStore/index";
import { useAgentsStore } from "@/piniaStore/agents";
import { useQueueStore } from "@/piniaStore/queue";
import { useTeamStore } from "@/piniaStore/team";
import pinia from "@/piniaStore/import";
import { PiniaVuePlugin } from "pinia";
Vue.use(PiniaVuePlugin);

import AmplifyHandler from "@/plugins/amplify";
const sign = require("jwt-encode");

let amplify = new AmplifyHandler();
const stateEnum = {
  0: "CONNECTING",
  1: "OPEN",
  2: "CLOSING",
  3: "CLOSED",
  4: "UN-DEFINED",
};
const emitter = new Vue({
  setup() {
    const activityStore = useActivityStore();
    const authenticationStore = useAuthenticationStore();
    const channelStore = useChannelStore();
    const chatStore = useChatStore();
    const contactStore = useContactStore();
    const contactsStore = useContactsStore();
    const uiStore = useUiStore();
    const permissionStore = usePermissionsStore();
    const hidStore = useHidStore();
    const holidayStore = useHolidayStore();
    const iframeIntegrationStore = useIframeIntegrationsStore();
    const logStore = useLogsStore();
    const mediaDeviceStore = useMediaDevicesStore();
    const settingStore = useSettingsStore();
    const phoneStore = usePhoneStore();
    const currentUserStore = useCurrentUserStore();
    const apiStore = useApiStore();
    const indexStore = useIndexStore();
    const agentStore = useAgentsStore();
    const queueStore = useQueueStore();
    const teamStore = useTeamStore();
    return {
      activityStore,
      authenticationStore,
      channelStore,
      chatStore,
      contactStore,
      contactsStore,
      uiStore,
      permissionStore,
      hidStore,
      holidayStore,
      iframeIntegrationStore,
      logStore,
      mediaDeviceStore,
      settingStore,
      phoneStore,
      currentUserStore,
      apiStore,
      indexStore,
      agentStore,
      queueStore,
      teamStore,
    };
  },
  // store,
  pinia,
  data() {
    return {
      specialToken: {
        encodedToken: "",
      },
      initiator: {},
      monitorInit: {},
      socketHeartBeatInterval: null,
      minutePassedCounter: 0,
      socketConnectionStatus: stateEnum["4"],
    };
  },
  computed: {
    currentUser() {
      return this.apiStore.api?.getUser ?? {};
    },
    agentSummary() {
      return this.agentStore.agentSummary ?? {};
    },
    timeRemainingForTokenExpiry() {
      let session = this.currentUser.session;
      const { idToken } = session;
      this.setToken(idToken);
      return DateTime.fromMillis(idToken?.getExpiration() * 1000).diffNow("minutes").minutes;
    },
  },
  methods: {
    connect() {
      initSocket();
    },
    send(message) {
      let event = JSON.parse(message);
      let payload = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        text: `[LAMBDA_SOCKET_CLIENT] [${this.agentSummary?.Username}] [${event.action}] : Sending request to socket server.`,
        messageContext: event,
      };

      console.debug("#SC - ", socket?.readyState, WebSocket.OPEN, WebSocket.CONNECTING, this.currentUser, payload);

      if (socket?.readyState === WebSocket.CLOSED) {
        queue.push(message);
        socket = null;
        this.open();
      } else if (socket?.readyState === WebSocket.OPEN) {
        console.debug("#SC - Sending Message :", event.action, payload);
        try {
          socket.send(message);
          this.logStore.addSocketLog(payload);
        } catch (error) {
          console.error("#SC - error in sending message : ", error);
          payload.level = "ERROR";
          payload.text = `[LAMBDA_SOCKET_CLIENT] [${this.agentSummary?.Username}] [${event.action}] : Error while sending request to server`;
          payload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
          this.logStore.addSocketLog(payload);
        }
      } else if (socket?.readyState === WebSocket.CONNECTING) {
        console.debug("#SC - Queueing Message :", event, event.action);
        queue.push(message);
      }
    },
    close() {
      clearInterval(this.socketHeartBeatInterval);
      this.minutePassedCounter = 0;
      this.socketHeartBeatInterval = null;
      let payload = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        text: "",
        messageContext: {
          BASE_URL: getWebSocketEndPoint(this.agentSummary?.Region),
          TOKEN: emitter.specialToken.encodedToken,
          InstanceId: this.agentSummary.InstanceId || emitter.specialToken.payload["custom:InstanceId"],
        },
      };
      try {
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${this.agentSummary?.Username}] [DISCONNECT] : Sending Disconnect Request to socket server.`;
        this.logStore.addSocketLog(payload);
        socket.close();
      } catch (error) {
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${this.agentSummary?.Username}] [DISCONNECT] : Error while trying to disconnect from socket server.`;
        payload.level = "ERROR";
        payload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
        this.logStore.addSocketLog(payload);
        console.error("#SC - error on closing socket :", error);
      }
    },
    open() {
      initSocket();
    },
    decode(token) {
      const base64Url = token.jwtToken.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    isReady() {
      return socket.readyState === WebSocket.OPEN;
    },
    setToken(token) {
      this.specialToken = { ...token };
      const decoded = this.decode(token);
      const reqInf = {
        instanceId: token.payload["custom:InstanceId"],
        _instanceAlias: decoded._instanceAlias,
        "custom:Username": decoded["custom:Username"],
        _sessionToken: decoded._sessionToken,
        _region: decoded._region,
        _secretAccessKey: decoded._secretAccessKey,
        _clientOwnedResources: decoded._clientOwnedResources,
        _authToken: decoded._authToken,
        _accessKeyId: decoded._accessKeyId,
        _customerIdentifier: decoded._customerIdentifier,
        exp: decoded.exp * 1000,
        iat: decoded.iat,
      };
      this.specialToken.encodedToken = sign(reqInf, reqInf._secretAccessKey);
    },

    getCoturnCredentials() {
      const requestCoturnCredentials = {
        action: "getCoturnCredentials",
        token: this.specialToken.encodedToken,
        instanceId: this.specialToken.payload["custom:InstanceId"],
      };

      this.send(JSON.stringify(requestCoturnCredentials));
    },

    setInitiatorData(payload) {
      this.initiator = {};
      this.initiator = payload;
      this.initiator.token = this.specialToken.encodedToken;
    },

    setMonitorInitiatorData(payload) {
      this.monitorInit = {};
      this.monitorInit = payload;
      this.monitorInit.token = this.specialToken.encodedToken;
    },

    async reConfigureToken() {
      let session = await Amplify.Auth.currentSession();
      const { idToken } = session;
      if (idToken) {
        this.setToken(idToken);
      }
    },

    async updateUserSessionInfo() {
      let currentSession;
      let user = this.currentUser;

      try {
        let session = await axios({
          method: "post",
          url: getApiRestEndpoint(this.agentSummary.Region) + "/guest/login",
          data: user,
        });

        await amplify.configure(this.agentSummary.Region);
        await Amplify.Auth.signIn(session.data.creds.CognitoUser, session.data.creds.CognitoPassword); //Signing In again to get new token.
        currentSession = await Auth.currentSession();
        this.apiStore.updateCurrentUser({ ...user, session: currentSession });
      } catch (err) {
        console.error("#SC - currentSession - Error : ", err);
      }
    },
    checkInternetConnection(event) {
      if (event.type === "online") {
        if (socket) {
          socket.close();
          socket = null;
        }
        initSocket();
      } else if (event.type === "change") {
        if (socket?.readyState === WebSocket.CLOSED || socket?.readyState === WebSocket.CLOSING) {
          socket.close();
          socket = null;
          initSocket();
        }
      }
    },
  },
  created() {
    window.addEventListener("online", (event) => this.checkInternetConnection(event));
    window.addEventListener("offline", (event) => this.checkInternetConnection(event));
    const conn = navigator?.connection;
    if (conn) {
      conn.addEventListener("change", (event) => {
        this.checkInternetConnection(event);
      });
    }
  },
  destroyed() {
    window.removeEventListener("online");
    window.removeEventListener("offline");
    const conn = navigator?.connection;
    if (conn) {
      conn.removeEventListener("change");
    }
  },
});

const initSocket = () => {
  if (!socket) {
    console.debug("#SC - Checking Socket : ", socket);
    const payload = {
      component: "LAMBDA_SOCKET",
      level: "INFO",
      text: "",
      messageContext: {
        BASE_URL: getWebSocketEndPoint(emitter.agentStore.agent?.Region),
        TOKEN: emitter.specialToken.encodedToken,
        InstanceId: emitter.specialToken.payload["custom:InstanceId"],
      },
    };

    try {
      payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [CONNECT] : Sending Connect Request to socket server.`;
      emitter.logStore.addSocketLog(payload);
      socket = new WebSocket(
        `${getWebSocketEndPoint(emitter.agentStore.agent?.Region)}?token=qw${emitter.specialToken.encodedToken}&instanceId=${
          emitter.specialToken.payload["custom:InstanceId"]
        }`
      );
      emitter.socketConnectionStatus = stateEnum[socket?.readyState || "4"];
    } catch (error) {
      payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [CONNECT] : Error while trying to connect to socket server.`;
      payload.level = "ERROR";
      payload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
      emitter.logStore.addSocketLog(payload);

      console.error("#SC - error in initializing socket : ", payload);
    }

    emitter.getCoturnCredentials();
    socket.onmessage = (msg) => {
      console.debug("#SC - Message Recieved : ", msg);
      const response = JSON.parse(msg.data);

      if (!response.data.error) {
        emitter.logStore.addSocketLog({
          level: "INFO",
          text: `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [${response.action}] : Message Recieved from server : ${response.action}`,
          messageContext: response.data,
        });
      } else {
        emitter.logStore.addSocketLog({
          level: "ERROR",
          text: `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [${response.action}] : Error Recieved from server while processing : ${response.action}`,
          messageContext: response.data,
        });
      }

      switch (response.action) {
        case "validateConnection":
          console.debug("#SC - Socket Connect Status : ", response.data);
          break;

        case "getCoturnCredentials":
          console.debug("#SC - Cotrun Status : ", response.data);
          emitter.teamStore.setCoturnCredentials(response.data);
          break;

        case "fetchUUID":
          if (response.data.error) {
            Vue.prototype.$Notice.error({
              title: response.data.message,
            });
            emitter.teamStore.setCallStatus(PeerCallStatus.InitialState);
          }
          if (response.data.type === "voiceCall" && !response.data.error) {
            emitter.initiator.uuid = response.data.uuid;
            emitter.teamStore.setupVoiceCall(emitter.initiator);
            emitter.teamStore.startCallTimer({
              InstanceId: emitter.initiator.InstanceId,
            });
          } else if (response.data.type === "monitor" && !response.data.error) {
            emitter.monitorInit.uuid = response.data.uuid;
            emitter.teamStore.fireInitiator(emitter.monitorInit, { root: 1 });
          }
          break;

        case `${emitter.currentUserStore.getProfileData?.Username}-offeredVoiceCall`:
          console.debug("#SC - Profile Data : ", emitter.currentUserStore.getProfileData, response.data);
          emitter.currentUserStore.onOfferedVoiceCall(response.data);
          break;

        case "setupVoiceCall":
          if (response.data.error) {
            Vue.prototype.$Notice.error({
              title: response.data.message,
            });
            emitter.logStore.addItem({
              component: "LAMBDA_SOCKET",
              level: "ERROR",
              text: `Action: Setup Voice Call, Error: ${response.data.message}`,
            });
            emitter.teamStore.setCallStatus(PeerCallStatus.InitialState);
            emitter.teamStore.destroyVoiceCall();
          }
          break;

        case "heartBeat":
          console.debug("#SC - heart-beat :  ", response.data);
          break;

        case "createMonitor":
          if (response.data.error) {
            Vue.prototype.$Notice.error({
              title: response.data.message,
            });
            emitter.logStore.addItem({
              component: "LAMBDA_SOCKET",
              level: "ERROR",
              text: `Action: Create Monitor, Error: ${response.data.message}`,
            });
            emitter.teamStore.deleteMonitorInitiator();
          }
          break;

        case `${emitter.currentUserStore.getProfileData?.Username}-destroyedMonitor`:
          if (window.mPeers) {
            window.mPeers.destroy();
            window.mPeers = null;
          } else {
            emitter.teamStore.deleteMonitorInitiator();
          }
          break;

        case `${emitter.currentUserStore.getProfileData?.Username}-answeredVoiceCall`:
          emitter.currentUserStore.onAnsweredVoiceCall(response.data);
          break;

        case `${emitter.currentUserStore.getProfileData?.Username}-destroyedVoiceCall`:
          emitter.currentUserStore.onDestroyedVoiceCall(response.data);
          break;

        case `${emitter.currentUserStore.getProfileData?.Username}-offeredMonitor`:
          emitter.currentUserStore.onOfferedMonitor(response.data);
          break;

        case `${emitter.currentUserStore.getProfileData?.Username}-AnsweredMonitor`:
          emitter.currentUserStore.onAnsweredMonitor(response.data);
          break;

        default:
          console.debug("#SC - Default Case Message : ", response.action, response.data);
          emitter.$emit(response.action, response.data);
          break;
      }
    };

    socket.onerror = (event) => {
      console.debug("#HAR : Socket Error : ", event);
      const payload = {
        component: "LAMBDA_SOCKET",
        level: "ERROR",
        text: `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [SOCKET_NATIVE_ERROR] : Some occured in the socket lib. Open to see details.`,
        messageContext: {
          event: event,
        },
      };
      payload.messageContext.error = JSON.stringify(event, ["isTrusted", "wasClean", "type", "code", "reason"]);
      console.error("#SC - Socket Error : ", event);
      emitter.logStore.addSocketLog(payload);
      emitter.socketConnectionStatus = stateEnum[socket?.readyState || "4"];
      // emitter.$emit("error", err);
    };

    socket.onopen = () => {
      console.debug("#SC - i am open", queue, stateEnum[socket?.readyState]);
      onSocketOpen();
      const payload = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
      };
      emitter.socketConnectionStatus = stateEnum[socket.readyState || "4"];
      emitter.socketHeartBeatInterval = setInterval(() => {
        if (emitter.minutePassedCounter > 4 && emitter.minutePassedCounter % 5 === 0) {
          let data = {
            action: "heartBeat",
            token: emitter.specialToken.encodedToken,
            instanceId: emitter.specialToken.payload["custom:InstanceId"],
            status: emitter.teamStore.getCallStatus || PeerCallStatus.InitialState,
          };
          emitter.send(JSON.stringify(data));
        }

        console.debug("#SC - Checking Token Expiry: ", emitter.timeRemainingForTokenExpiry);
        if (emitter.timeRemainingForTokenExpiry <= 10) {
          payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [UPDATE_TOKEN] : Updating token.`;
          payload.messageContext = { oldToken: emitter.specialToken.encodedToken };
          emitter.logStore.addSocketLog(payload);
          emitter.updateUserSessionInfo();
          emitter.reConfigureToken();
          payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [UPDATE_TOKEN] : Token updated.`;
          payload.messageContext = { newToken: emitter.specialToken.encodedToken };
          emitter.logStore.addSocketLog(payload);
        }

        if (socket?.readyState === WebSocket.CLOSED || socket?.readyState === WebSocket.CLOSING) {
          clearInterval(emitter.socketHeartBeatInterval);
          emitter.open();
        }

        emitter.minutePassedCounter = emitter.minutePassedCounter + 1;
        if (emitter.minutePassedCounter >= 60 && emitter.teamStore.callStatus === "InitialState") {
          console.debug("#SC - Reconnecting");
          payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [REFRESH_CONNECTION] : Refreshing the connection.`;
          payload.messageContext = { socketState: stateEnum[socket?.readyState || 4] };
          emitter.logStore.addSocketLog(payload);
          emitter.close();
          socket = null;
          emitter.open();
          emitter.minutePassedCounter = 0;
        }
      }, 60000);
    };

    socket.onclose = (event) => {
      console.debug("#HAR - Socket was closed : ", event);
      const payload = {
        component: "LAMBDA_SOCKET",
        level: "ERROR",
        text: `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [SOCKET_NATIVE_CLOSE] : Socket connection closed due to some error. Open to see details.`,
        messageContext: {
          event: event,
        },
      };
      payload.messageContext.error = JSON.stringify(event, ["isTrusted", "wasClean", "type", "code", "reason"]);
      console.error("#SC - Socket Error : ", event);
      emitter.logStore.addSocketLog(payload);
      emitter.socketConnectionStatus = stateEnum[socket.readyState || "4"];
    };

    const onSocketOpen = () => {
      if (socket?.readyState !== WebSocket.OPEN) {
        setImmediate(() => {
          onSocketOpen();
        }, 1000);
      } else {
        const payload = {
          component: "LAMBDA_SOCKET",
          level: "INFO",
        };
        while (queue.length > 0) {
          console.debug("loop queue", queue);
          const event = JSON.parse(queue.pop());
          try {
            payload.level = "INFO";
            payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [${event.action}] : Sending request to socket server.`;
            payload.messageContext = event;
            emitter.logStore.addSocketLog(payload);
            socket.send(JSON.stringify(event));
          } catch (error) {
            console.error("#SC - error socket.send : ", error);
            payload.text = `[LAMBDA_SOCKET_CLIENT] [${emitter.agentSummary?.Username}] [${event.action}] : Error while sending request to server`;
            payload.messageContext.error = error;
            emitter.logStore.addSocketLog(payload);
          }
        }
      }
    };
  }
};

export default emitter;
